<template>
<div class="PushSelect">
  
  <div class="PushSelect__label">
    {{ `${title} (0-10)` }}
  </div>
  
  <div
    class="PushSelect__scores"
  >
    <div
      v-for="index in 10" :key="index"
      :class="[
        `PushSelect__score`,
        {'PushSelect__score-selected': score === index},
        {'PushSelect__disabled': disabled}
      ]"
      @click="$emit('update:score', index)"
    >
      {{ index }}
    </div>
    <div
      :class="[
        `PushSelect__deny`,
        {'PushSelect__deny-selected': score === 0},
        {'PushSelect__disabled': disabled}
      ]"
      @click="$emit('update:score', 0)"
    >
      {{ `No ${title} (0)` }}
    </div>
  </div>
  
  
  
</div>
</template>

<script>
export default {
  name: "PushSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    score: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.PushSelect {
  
  &__disabled {
    cursor: not-allowed !important;
    &:active {
      pointer-events: none;
    }
    
    &.PushSelect__score-selected,
    &.PushSelect__deny-selected {
      background-color: #d7d7d7;
    }
  }
  
  &__scores {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }
  
  &__score {
    aspect-ratio: 1; // https://caniuse.com/mdn-css_properties_aspect-ratio
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $neutral11;
    background-color: $neutral2;
    cursor: pointer;
  }
  
  &__score-selected:not(&__disabled),
  &__deny-selected:not(&__disabled) {
    color: white !important;
    background-color: $primary8 !important;
  }
  
  &__deny {
    aspect-ratio: 5.4/1;
    display: flex;
    grid-column: 1/6;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $neutral11;
    background-color: $neutral2;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  }
  
  &__label {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $primary10;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  
}
</style>