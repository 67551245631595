<template>
<AuthLayout>
<template #content>
  
  <div class="SamplePage">
    
    <div class="SamplePage__title">
      {{ `Sample ${number} Collection` }}
    </div>
    
    <div class="SamplePage__body">
      
      <div class="SamplePage__paragraph">
        Please rate the following symptoms on a scale from 0-10, with 0 = no symptoms and 10 = severe symptoms,
        each time you take a breath sample.
      </div>
      
      <div class="SamplePage__form">
      
        <PushSelect
          :title="`bloating`"
          v-model:score="form_bloating"
          :disabled="request?.status === 'test_completed'"
        />
        
        <PushSelect
          :title="`abdominal pain`"
          v-model:score="form_abdominal_pain"
          :disabled="request?.status === 'test_completed'"
        />
        
        <PushSelect
          :title="`nausea`"
          v-model:score="form_nausea"
          :disabled="request?.status === 'test_completed'"
        />
        
      </div>

      <a-button
        class="ant-btn-huge SamplePage__submit"
        type="green"
        size="large"
        :disabled="[form_bloating, form_abdominal_pain, form_nausea].some(i => i === null) || request?.status === 'test_completed'"
        @click="onSubmit"
      >
        {{ active?.number === sampleInit?.number ? "Next" : "Save" }}
      </a-button>
      
    </div>
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import PushSelect from "@/ui-kit/push-select/PushSelect"
import { isSuccess } from "@/helpers/http.helper"
import { mapFields } from "@/helpers/form.helper"
import { PatientSamplesService } from '@/services/api_v1/patient'
import { ApiResult } from "@/models"
import { Sample } from "@/models/api_v1"
import { mapActions, mapGetters, mapMutations } from "vuex"
import _ from "lodash";
import { toast } from "@/helpers/error.helper"


let formSetup = {
  bloating: {},
  abdominal_pain: {},
  nausea: {},
}

export default {
  name: "SamplePage",
  components: {
    PushSelect,
    AuthLayout,
  },
  data() {
    return {
      loading: false,
      formSetup,
    }
  },
  computed: {
    ...mapFields({
      formSetup,
      props: ['fields', 'formState'],
      getter: (vm, field) => _.get(vm.sampleInit, field),
      setter: (vm, field, value) => vm.setSampleInitField({ field, value }),
    }),
    ...mapGetters({
      isRequest: 'PatientRequestStore/getIsInit',
      request: 'PatientRequestStore/getInit',
      allSamples: 'PatientSampleStore/getAll',
      sampleInit: 'PatientSampleStore/getInit',
    }),
    active() {
      return this.allSamples?.find((i) => i.isEmpty)
    },
    number() {
      return Number(this.$route.params.sample)
    },
    sample() {
      return this.allSamples?.find((i) => i.number === this.number)
    },
  },
  methods: {
    ...mapActions({
      sampleFetch: 'PatientSampleStore/fetch',
      fetchRequest: 'PatientRequestStore/fetchInit',
    }),
    ...mapMutations({
      setSampleInit: 'PatientSampleStore/setItemInit',
      setSampleInitField: 'PatientSampleStore/setItemInitField',
      updateSample: 'PatientSampleStore/updateItem',
    }),
    async onSubmit() {
      let payload = this.sampleInit.toApi()
      this.loading = true
      await PatientSamplesService.update(payload)
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          let result = new ApiResult(response),
              item = Sample.fromApi(result.data.data)
          this.updateSample(item)
          toast ({ type: 'success', msg: 'Success' })
          if (this.active && this.active?.number - 1 === 1) {
            this.$router.push({ name: 'patient.samples.substrate' })
          } else {
            this.$router.push({ name: 'patient.samples' })
          }
        })
        .catch((err) => {
          toast ({ msg: err?.data?.data?.message })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  async mounted() {
    if (!this.isRequest) {
      await this.fetchRequest()
    }
    if (!this.allSamples) {
      await this.sampleFetch()
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          this.setSampleInit(this.sample)
        })
    } else {
      this.setSampleInit(this.sample)
    }
  },
}
</script>

<style lang="scss" scoped>
.SamplePage {
  padding: 0 15px;
  
  &__body {
    padding: 14px 0 24px 0
  }
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
  &__paragraph {
    color: $primary10;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  
  &__submit {
    margin-top: 32px;
  }
  
  &__form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 32px;
  }
  
}
</style>